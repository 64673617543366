<template>
  <div>
    <AppTable
      :rows="purchases.value"
      :columns="columns"
      :is-loading="requestInProgress"
      :pagination="{
        limit: purchases.limit,
        count: purchases.count,
        page: purchases.page,
      }"
      @change-limit="changePageLimit"
      @change-page="changePage"
    >
      <template #prependFilters>
        <b-row>
          <b-col
            md="3"
            xl="3"
            class="mb-1"
          >
            <b-form-group>
              <label class="mr-1">TG username</label>
              <b-form-input
                v-model="searchUser"
                placeholder="TG username"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="3"
            xl="3"
            class="mb-1"
          >
            <b-form-group>
              <label class="mr-1">TG ID</label>
              <b-form-input
                v-model="searchID"
                placeholder="TG ID"
                type="text"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="1"
            xl="1"
            class="mb-1 mt-auto pb-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="requestInProgress"
              class="mt-auto"
              type="button"
              variant="primary"
              @click="search"
            >
              <feather-icon
                icon="SearchIcon"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </template>

      <template #default="{ column, row, formattedRow} = {}">
        <TableRow
          :row="row"
          :column="column"
          :formatted-row="formattedRow"
        />
        <span v-if="column.field === 'user'">
          <b-badge
            v-if="row.user.telegramId"
            class="text-white"
            variant="secondary"
            :to="`/user/${row.user.id}`"
            target="_blank"
          >
            {{ row.user.telegramId }}
          </b-badge>

          <b-button
            v-if="row.user.id"
            variant="link"
            class="font-16 text-left"
            :href="`https://t.me/${row.user.telegramUsername}`"
            target="_blank"
          >
            {{ row.user.telegramUsername || 'User' }}
          </b-button>
        </span>

        <span v-if="column.field === 'wallet'">
          <b-badge
            v-if="row.wallet && row.wallet.isCefi"
            class="text-white"
            variant="primary"
          >
            CEFI
          </b-badge>
          <b-badge
            v-else
            class="text-white"
            variant="success"
          >
            DEFI
          </b-badge>

          <b-button
            v-if="row.wallet && row.wallet.address"
            variant="link"
            class="pl-0 font-16 text-left"
            @click="doCopy(row.wallet.address)"
          >
            {{ row.wallet.address }}
          </b-button>
        </span>

        <span v-if="column.field === 'item'">
          <b-badge

            class="text-white"
            variant="success"
          >
            {{ row.item.slug }}
          </b-badge>
          {{ row.item.price.amount }} {{ row.item.price.currency }}
        </span>
      </template>
    </AppTable>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapGetters } from 'vuex'
import store from '@/store/index'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'
import transactions from '@/mixins/transactions'
import columns from './config/tableConfig'
import TableRow from '@/components/TableRow.vue'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'HoldPurchasesView',
  components: {
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    AppTable,
    TableRow,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError, transactions],

  data() {
    return {
      columns,
      currenPage: 1,
      selectPerPage: 30,
      rowsPerPageOptions: [10, 30, 50],

      searchUser: '',
      searchID: '',
    }
  },

  computed: {
    ...mapGetters({
      purchases: 'hold/purchases',
    }),
    direction() {
      return store.state.appConfig.isRTL
    },
  },

  mounted() {
    this.initState()
  },

  methods: {
    ...mapActions({
      fetchHoldStorePurchases: 'hold/fetchHoldStorePurchases',
    }),

    createQueryConfig(config) {
      return {
        page: this.purchases.page,
        limit: this.purchases.limit,

        ...config,
      }
    },

    initState() {
      const limit = this.selectPerPage
      const query = this.createQueryConfig({ limit })
      this.fetchPurchases(query)
    },

    search() {
      const query = this.createQueryConfig({
        telegramUsername: this.searchUser,
        telegramId: this.searchID,
        page: 1,
      })
      return this.fetchPurchases(query)
    },

    changePageLimit(pageLimit) {
      const query = this.createQueryConfig({ limit: pageLimit })
      return this.fetchPurchases(query)
    },
    changePage(pageNumber) {
      const query = this.createQueryConfig({ page: pageNumber })
      return this.fetchPurchases(query)
    },

    fetchPurchases(query) {
      if (this.requestInProgress) return

      this.waitRequest(() => this.fetchHoldStorePurchases(query))
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-good-table.scss';
</style>
