var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppTable',{attrs:{"rows":_vm.purchases.value,"columns":_vm.columns,"is-loading":_vm.requestInProgress,"pagination":{
      limit: _vm.purchases.limit,
      count: _vm.purchases.count,
      page: _vm.purchases.page,
    }},on:{"change-limit":_vm.changePageLimit,"change-page":_vm.changePage},scopedSlots:_vm._u([{key:"prependFilters",fn:function(){return [_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1"},[_vm._v("TG username")]),_c('b-form-input',{attrs:{"placeholder":"TG username","type":"text"},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"md":"3","xl":"3"}},[_c('b-form-group',[_c('label',{staticClass:"mr-1"},[_vm._v("TG ID")]),_c('b-form-input',{attrs:{"placeholder":"TG ID","type":"text"},model:{value:(_vm.searchID),callback:function ($$v) {_vm.searchID=$$v},expression:"searchID"}})],1)],1),_c('b-col',{staticClass:"mb-1 mt-auto pb-1",attrs:{"md":"1","xl":"1"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-auto",attrs:{"disabled":_vm.requestInProgress,"type":"button","variant":"primary"},on:{"click":_vm.search}},[_c('feather-icon',{attrs:{"icon":"SearchIcon","size":"18"}})],1)],1)],1)]},proxy:true},{key:"default",fn:function(ref){
    if ( ref === void 0 ) ref = {};
    var column = ref.column;
    var row = ref.row;
    var formattedRow = ref.formattedRow;
return [_c('TableRow',{attrs:{"row":row,"column":column,"formatted-row":formattedRow}}),(column.field === 'user')?_c('span',[(row.user.telegramId)?_c('b-badge',{staticClass:"text-white",attrs:{"variant":"secondary","to":("/user/" + (row.user.id)),"target":"_blank"}},[_vm._v(" "+_vm._s(row.user.telegramId)+" ")]):_vm._e(),(row.user.id)?_c('b-button',{staticClass:"font-16 text-left",attrs:{"variant":"link","href":("https://t.me/" + (row.user.telegramUsername)),"target":"_blank"}},[_vm._v(" "+_vm._s(row.user.telegramUsername || 'User')+" ")]):_vm._e()],1):_vm._e(),(column.field === 'wallet')?_c('span',[(row.wallet && row.wallet.isCefi)?_c('b-badge',{staticClass:"text-white",attrs:{"variant":"primary"}},[_vm._v(" CEFI ")]):_c('b-badge',{staticClass:"text-white",attrs:{"variant":"success"}},[_vm._v(" DEFI ")]),(row.wallet && row.wallet.address)?_c('b-button',{staticClass:"pl-0 font-16 text-left",attrs:{"variant":"link"},on:{"click":function($event){return _vm.doCopy(row.wallet.address)}}},[_vm._v(" "+_vm._s(row.wallet.address)+" ")]):_vm._e()],1):_vm._e(),(column.field === 'item')?_c('span',[_c('b-badge',{staticClass:"text-white",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(row.item.slug)+" ")]),_vm._v(" "+_vm._s(row.item.price.amount)+" "+_vm._s(row.item.price.currency)+" ")],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }