export default [
  {
    label: 'User',
    field: 'user',
  },
  {
    label: 'Item',
    field: 'item',
  },
  {
    label: 'Wallet',
    field: 'wallet',
  },
  {
    label: 'Created At',
    field: 'createdAt',
  },
]
